import { useState, useEffect, useRef } from 'react'
import * as React from 'react'
import { ReviewFormProps } from './ReviewForm.types'
import './ReviewForm.scss'
import {
  Const_ApiURL, Const_DefaultReviewObect,
  Const_ReviewForm_AuthorLength, Const_ReviewForm_CommentLength
} from "../../constants"
import axios from "axios"
import RatingStars from "../RatingStars/RatingStars"

const ReviewForm: React.FunctionComponent<ReviewFormProps> = ({
                                                                defaultReview,
                                                                updateList
                                                              }) => {
  const [ author, setAuthor ] = useState<string>(defaultReview.author)
  const [ comment, setComment ] = useState<string>(defaultReview.comment)
  const [ rating, setRating ] = useState<number>(defaultReview.rating)

  const formRef = useRef<null | HTMLFormElement>(null)

  useEffect(() => {
    // on change update form
    setAuthor(defaultReview.author)
    setComment(defaultReview.comment)
    setRating(defaultReview.rating)
  }, [ defaultReview ]);

  const [ processing, setProcessing ] = useState<boolean>(false)
  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault()
    setProcessing(true)
    const apiAction = defaultReview.id === Const_DefaultReviewObect.id
      ? () => axios.post(Const_ApiURL + '/reviews', {
        author,
        rating,
        comment
      })
      : () => axios.put(Const_ApiURL + '/reviews/' + defaultReview.id, {
        author,
        rating,
        comment
      })
    apiAction()
      .then(() => {
        console.log('Submit success')
        updateList()
      })
      .catch((error) => {
        // handle error
        console.log(error);
      })
      .finally(() => {
        return setProcessing(false)
      })
  }

  const handleRemove = (event: React.MouseEvent) => {
    event.preventDefault()
    setProcessing(true)
    axios.delete(Const_ApiURL + '/reviews/' + defaultReview.id)
      .then(() => {
        console.log('Delete success')
        updateList()
        if (formRef.current) formRef.current.reset()
      })
      .catch((error) => {
        // handle error
        console.log(error);
      })
      .finally(() => {
        return setProcessing(false)
      })
  }

  const onChangeAuthor = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAuthor(event.currentTarget.value)
  }

  const onChangeComment = (event: React.ChangeEvent<HTMLInputElement>) => {
    setComment(event.currentTarget.value)
  }

  return <React.Fragment>
    <h3 className="mt-3">{defaultReview.id !== Const_DefaultReviewObect.id ? 'Update' : 'Submit'} your review</h3>
    <form onSubmit={handleSubmit} ref={formRef} className="review-form">
      <div className="mb-3">
        <label className="form-label">Rating (required)</label>
        {processing}
        <RatingStars value={rating}
                     canSetValue={true}
                     disabled={processing}
                     onChangeValue={setRating}/>
      </div>
      <div className="mb-3">
        <label htmlFor="formReviewAuthor" className="form-label">Name (optional)</label>
        <input type="text"
               defaultValue={defaultReview.author}
               maxLength={Const_ReviewForm_AuthorLength}
               disabled={processing}
               onChange={onChangeAuthor}
               className="form-control"
               id="formReviewAuthor"
               aria-describedby="authorHelp"/>
        <div id="authorHelp" className="form-text">Review with name is more trusted.</div>
      </div>
      <div className="mb-3">
        <label htmlFor="formReviewComment" className="form-label">Describe your experience (optional)</label>
        <input type="text"
               defaultValue={defaultReview.comment}
               className="form-control"
               maxLength={Const_ReviewForm_CommentLength}
               disabled={processing}
               onChange={onChangeComment}
               id="formReviewComment"
               aria-describedby="commentHelp"/>
        <div id="commentHelp" className="form-text">Shortly about your positive and negative experiences.</div>
      </div>
      <div className="mb-3">
        {defaultReview.id > 0
          ? (
            <React.Fragment>
              <button type="submit"
                      className="update-button me-3 mb-3 mb-lg-0"
                      disabled={processing}>Update review
              </button>
              <button type="button"
                      className="remove-button"
                      onClick={handleRemove}
                      disabled={processing}>Remove review
              </button>
            </React.Fragment>)
          : <button type="submit"
                    className="create-button"
                    disabled={processing}>Submit review</button>
        }
      </div>

    </form>
  </React.Fragment>
}

export default ReviewForm