import { useState } from 'react'
import * as React from 'react'
import { ReviewProps } from './Review.types'
import './Review.scss'
import RatingStars from "../RatingStars/RatingStars";

const Review: React.FC<ReviewProps> = ({
                                         id,
                                         author,
                                         rating,
                                         comment,
                                         updated_at,
                                         ownReviewId
                                       }) => {
  const timeStamp = new Date(updated_at)
  return <div className="review bg-light px-3 py-1 mb-3">
    {author !== '' ? author :
      <i>Anonymous
        customer</i>}{ownReviewId === id && ' (You)'} at {
    timeStamp.toLocaleString('en', { dateStyle: 'short' })}{" "}
    {`${timeStamp.getHours()}`.padStart(2, "0")}:{`${timeStamp.getMinutes()}`.padStart(2, "0")}
    <RatingStars value={rating}/>
    {comment !== '' && (
      <div className="comment">
        {comment}
      </div>)}
  </div>
}

export default Review