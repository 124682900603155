export const Const_ContactDetails_Phone = '+3620-2020-2021'
export const Const_ContactDetails_Address = "H2766 Tápiószele, Posványos Street 211."
export const Const_ContactDetails_Email = "store@example.com"

export const Const_ApiURL = process.env.NODE_ENV !== 'production' ? 'http://127.0.0.1:8000/api' : 'https://laravel.ovoneni.com/api'
export const Const_ReviewListIncrement = 3
export const Const_ReviewForm_AuthorLength = 128
export const Const_ReviewForm_CommentLength = 256

export const Const_DefaultReviewObect = Object.freeze({
  id: 0,
  author: '',
  comment: '',
  rating: 1,
  updated_at: ''
})
