import * as React from 'react'
import { useState, useEffect } from 'react'
import './App.scss'
import axios from "axios"

import RatingStars from "./components/RatingStars/RatingStars"
import Review from "./components/Review/Review";

import {
  ReviewBaseProps,
  ReviewListResponseProps
} from './types'

import { calculateAverageRating, isOpenNow } from './utils'

import {
  Const_ContactDetails_Address,
  Const_ApiURL,
  Const_ContactDetails_Email,
  Const_ContactDetails_Phone,
  Const_ReviewListIncrement,
  Const_DefaultReviewObect
} from './constants'
import ReviewForm from "./components/ReviewForm/ReviewForm";


const App: React.FunctionComponent = () => {
  const [ initialized, setInitialized ] = useState<boolean>(false)
  const [ reviews, setReviews ] = useState<Array<ReviewBaseProps>>([])
  const [ ownReviewId, setOwnReviewId ] = useState<number>(0)
  const [ reviewListCount, setReviewListCount ] = useState<number>(Const_ReviewListIncrement)

  const onClickShowMoreReviews = (event: React.MouseEvent) => {
    event.preventDefault()
    setReviewListCount(reviewListCount + Const_ReviewListIncrement)
  }

  const handleReviewListUpdate = () => {
    axios.get(Const_ApiURL + '/reviews')
      .then((response) => {
        if (response.data !== undefined &&
          typeof response.data === "object" &&
          response.data !== null) {
          const data: ReviewListResponseProps = response.data
          if (typeof data.reviews === 'object' && Array.isArray(data.reviews)) {
            setReviews(data.reviews)
          }
          if (typeof data.ownReviewId === 'number') {
            setOwnReviewId(data.ownReviewId)
          }
        }

      })
      .catch((error) => {
        // handle error
        console.log(error);
      })
      .finally(() => {
        return setInitialized(true)
      })
  }

  useEffect(() => {
    handleReviewListUpdate()
  }, []);


  const goToReviews = (event: React.MouseEvent) => {
    event.preventDefault()
    const reviewsEl = document.getElementById('reviews')
    if (reviewsEl !== null) {
      reviewsEl.scrollIntoView({ behavior: 'smooth' })
    }
  }

  const defaultReviewData = (): ReviewBaseProps => {
    const _match = reviews.find(review => review.id === ownReviewId)
    if (_match !== undefined) {
      return _match
    } else {
      return Const_DefaultReviewObect
    }
  }

  const averageRating = calculateAverageRating(reviews)
  const ownReview = defaultReviewData()
  return (
    <React.Fragment>
      <div className="container ">
        <div className="row align-items-center">
          <div className="col-12 col-lg-6 store-images">
            <div className="images-wrapper">
              <div className="images">
                <img src="/store-1.jpg" className="d-block w-100 shadow-sm" alt="store interior"/>
              </div>
              <div className="thumbs d-none d-lg-block">
                <div className="row">
                  <div className="col">
                    <img src="/store-1.jpg" className="d-block w-100 shadow-sm" alt="store interior"/>
                  </div>
                  <div className="col">
                    <img src="/store-2.jpg" className="d-block w-100 shadow-sm" alt="store interior"/>
                  </div>
                  <div className="col">
                    <img src="/store-3.jpg" className="d-block w-100 shadow-sm" alt="store interior"/>
                  </div>
                </div>
              </div>
              <div className="background"/>
            </div>
          </div>
          <div className="col-12 col-lg-6 store-data bg-white">
            <div className="">
              <h1 className="">Example Store at Tápiószele</h1>
              <div className="d-flex align-items-center mb-3">
                <RatingStars value={averageRating}/>
                <small className="ms-3">{averageRating}/5 (<a href="#reviews"
                                                              onClick={goToReviews}>{reviews.length + " "}
                  reviews</a>)</small>
              </div>
              <ul className="list-unstyled">
                <li className="mb-3 d-flex w-100">
                  <div className="icon">
                    <i className="bi bi-shop"/>
                  </div>
                  <div>
                    {Const_ContactDetails_Address}
                  </div>
                </li>
                <li className="mb-3 d-flex w-100">
                  <div className="icon">
                    <i className="bi bi-clock"/>
                  </div>
                  <div>
                    <b className="">{isOpenNow() ? 'Open' : 'Closed'}</b> - From Monday
                    to Friday 9 a.m.
                    to 6 p.m.
                  </div>
                </li>
                <li className="mb-3 d-flex w-100">
                  <div className="icon">
                    <i className="bi bi-envelope"/>
                  </div>
                  <div>
                    <a href={"mailto:" + Const_ContactDetails_Email}>
                      {Const_ContactDetails_Email}
                    </a>
                  </div>
                </li>
                <li className="mb-3 d-flex w-100">
                  <div className="icon">
                    <i className="bi bi-phone"/>
                  </div>
                  <div>
                    {Const_ContactDetails_Phone}
                  </div>
                </li>
                <li className="mb-3 d-flex w-100">
                  <a className="call-now" href={"tel:" + Const_ContactDetails_Phone}>
                    Call now</a>
                </li>
                <li className="mb-3">
                  <b>About us</b>
                  <p>
                    In contrast to a landing page, your About Us page is the ideal place to accommodate
                    a number of
                    objectives: Communicate the story of your business and why you started it. Describe the customers or
                    the cause that your business serves. Explain your business model or how your products are made.
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div id="reviews" className="container pt-3">
        <div className="row justify-content-center">
          <div className="col-12 col-lg-6">
            <h2>Reviews</h2>
            {reviews.slice(0, reviewListCount).map((review) => <Review
                key={review.id}
                ownReviewId={ownReview.id}
                {...review}
              />
            )}
            {reviewListCount < reviews.length && <div>
              Showing {reviewListCount < reviews.length ? reviewListCount : reviews.length} of {reviews.length} reviews
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              - <a
              href="#" onClick={onClickShowMoreReviews}>Show
              more</a>
            </div>}
            <ReviewForm
              updateList={handleReviewListUpdate}
              defaultReview={ownReview}/>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default App;
