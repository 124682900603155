import { ReviewBaseProps } from "./types"

export const isOpenNow = (): boolean => {
  const d = new Date();
  const n = d.getDay()
  const h = d.getHours();

  return n > 0 && n < 6 && h >= 9 && h < 18
}

export const calculateAverageRating = (reviews: Array<ReviewBaseProps>): number => {
  let sum = 0, count = 0
  reviews.forEach(review => {
    sum += review.rating
    count++
  })
  return parseFloat((sum / count).toFixed(2))
}