import { useState, useEffect } from 'react'
import * as React from 'react'
import { RatingStarsProps } from './RatingStars.types'
import './RatingStars.scss'

const RatingStars: React.FC<RatingStarsProps> = ({
                                                   value,
                                                   canSetValue,
                                                   onChangeValue,
                                                   disabled
                                                 }) => {
  if (value > 5 || value < 1) throw new Error('RatingStars value prop must be between 1 and 5')

  const [ innerValue, setInnerValue ] = useState<number>(value)
  useEffect(() => {
    setInnerValue(value)
  }, [ value ]);

  const customOnChangeValue = (event: React.MouseEvent, newValue: number) => {
    if (canSetValue === true && onChangeValue !== undefined) {
      setInnerValue(newValue)
      onChangeValue(newValue)
    }
  }

  const fillWidth = 100 / 5 * innerValue

  return <div className={
    "rating-stars d-flex justify-content-between"
    + (canSetValue === true ? " editable" : "")
    + (disabled === true ? " disabled" : "")}>
    <div className="fill" style={{ width: `${fillWidth}%` }}>
      <div className="bar d-flex justify-content-between">
        <i className="bi bi-star-fill"
           onClick={event => customOnChangeValue(event, 1)}/>
        <i className="bi bi-star-fill"
           onClick={event => customOnChangeValue(event, 2)}/>
        <i className="bi bi-star-fill"
           onClick={event => customOnChangeValue(event, 3)}/>
        <i className="bi bi-star-fill"
           onClick={event => customOnChangeValue(event, 4)}/>
        <i className="bi bi-star-fill"
           onClick={event => customOnChangeValue(event, 5)}/>
      </div>
    </div>
    <i className="bi bi-star-fill"
       onClick={event => customOnChangeValue(event, 1)}/>
    <i className="bi bi-star-fill"
       onClick={event => customOnChangeValue(event, 2)}/>
    <i className="bi bi-star-fill"
       onClick={event => customOnChangeValue(event, 3)}/>
    <i className="bi bi-star-fill"
       onClick={event => customOnChangeValue(event, 4)}/>
    <i className="bi bi-star-fill"
       onClick={event => customOnChangeValue(event, 5)}/>
  </div>
}

export default RatingStars